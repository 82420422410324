import React from "react";
import { graphql, Link } from "gatsby";
import _ from "lodash";
import urljoin from "url-join";
import { DiscussionEmbed } from "disqus-react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PostCard from "../components/post-card/post-card";
import PostDetails from "../components/post-details/post-details";
import Sidebar2 from "../containers/sidebar2";
import Iframely from "../components/iframely";

import {
	FacebookShareButton,
	TwitterShareButton,
	PinterestShareButton,
	RedditShareButton,
} from "react-share";
import {
	IoLogoFacebook,
	IoLogoTwitter,
	IoLogoPinterest,
	IoLogoReddit,
} from "react-icons/io";
import {
	BlogPostDetailsWrapper,
	RelatedPostWrapper,
	RelatedPostItems,
	RelatedPostTitle,
	RelatedPostItem,
	BlogPostFooter,
	PostShare,
	PostTags,
	BlogPostComment,
	BlogDetailsContent,
	// BlogPostCatch,
	BlogPostsWrapper,
} from "./templates.style";

const BlogPostTemplate = (props: any) => {
	const {
		title,
		excerpt,
		date,
		modified,
		featuredImage,
		content,
		uri,
		tags,
		// catchText,
	} = props.data.wpPost;
	const coverUrl = featuredImage.node.localFile.publicURL;
	const siteUrl = props.data.site.siteMetadata.siteUrl;
	const shareUrl = urljoin(siteUrl, uri);
	const disqusConfig = {
		shortname: process.env.GATSBY_DISQUS_NAME,
		config: { identifier: uri, title },
	};
	const { edges: relatedPosts } = props.data.allWpPost;

	// console.log("title: " + title);
	// console.log("excerpt: " + excerpt);
	// console.log("date: " + date);
	// console.log("modified: " + modified);
	// console.log("coverUrl: " + coverUrl);
	// console.log("uri: " + uri);
	// console.log("catchText: " + catchText.text);
	// console.log("shareUrl: " + shareUrl);

	// console.log("featuredImage⏬ ");
	// console.dir(featuredImage);

	// console.log("content⏬");
	// console.dir(content);

	// console.log("tags⏬");
	// console.dir(tags);

	// console.log("disqusConfig⏬");
	// console.dir(disqusConfig);

	// console.log("relatedPosts⏬");
	// console.dir(relatedPosts);

	return (
		<Layout>
			<Iframely />
			<SEO
				title={title}
				description={excerpt}
				imageUrl={urljoin(
					siteUrl,
					featuredImage.node.localFile.publicURL
				)}
			/>
			{/* <BlogPostCatch>{catchText.text}</BlogPostCatch> */}
			<BlogPostsWrapper>
				<BlogPostDetailsWrapper>
					<BlogDetailsContent>
						<PostDetails
							// catchText={catchText.text}
							title={title}
							date={date}
							tags={tags == null ? null : tags}
							modified={modified}
							preview={
								featuredImage == null
									? null
									: featuredImage.node.localFile
											.childImageSharp.gatsbyImageData
							}
							description={content}
						/>

						{/* <BlogPostFooter>
						<PostShare>
							<span>Share This:</span>
							<FacebookShareButton url={shareUrl} quote={excerpt}>
								<IoLogoFacebook />
							</FacebookShareButton>
							<TwitterShareButton url={shareUrl} title={title}>
								<IoLogoTwitter />
							</TwitterShareButton>
							<PinterestShareButton
								url={shareUrl}
								media={urljoin(siteUrl, coverUrl)}
							>
								<IoLogoPinterest />
							</PinterestShareButton>
							<RedditShareButton url={shareUrl} title={title}>
								<IoLogoReddit />
							</RedditShareButton>
						</PostShare>
					</BlogPostFooter> */}

						<BlogPostComment>
							{/* <DiscussionEmbed {...disqusConfig} /> */}
						</BlogPostComment>
					</BlogDetailsContent>
					{relatedPosts.length !== 0 && (
						<RelatedPostWrapper>
							<RelatedPostTitle>関連する記事</RelatedPostTitle>
							<RelatedPostItems>
								{relatedPosts.map(({ node }: any) => {
									const relatedTags: any =
										node.tags.nodes.map((tag: any) => {
											return tag.name;
										});
									// Random Placeholder Color
									const placeholderColors = [
										"#55efc4",
										"#81ecec",
										"#74b9ff",
										"#a29bfe",
										"#ffeaa7",
										"#fab1a0",
										"#e17055",
										"#0984e3",
										"#badc58",
										"#c7ecee",
									];
									const setColor =
										placeholderColors[
											Math.floor(
												Math.random() *
													placeholderColors.length
											)
										];
									// console.log("post------------------------");

									// console.log(node.title);
									// console.log(node.uri);
									// console.log(
									// 	node.featuredImage.node.localFile
									// 		.childImageSharp.gatsbyImageData
									// );
									// console.log(node.relatedTags);

									return (
										<RelatedPostItem key={node.uri}>
											<PostCard
												title={node.title}
												url={node.uri}
												image={
													node.featuredImage == null
														? null
														: node.featuredImage
																.node.localFile
																.childImageSharp
																.gatsbyImageData
												}
												modified={node.modified}
												tags={relatedTags}
												placeholderBG={setColor}
											/>
										</RelatedPostItem>
									);
								})}
							</RelatedPostItems>
						</RelatedPostWrapper>
					)}
				</BlogPostDetailsWrapper>
				<Sidebar2 />
			</BlogPostsWrapper>
		</Layout>
	);
};

export default BlogPostTemplate;

export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!, $tag: [String!]) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		wpPost(slug: { eq: $slug }) {
			id
			excerpt
			content
			uri
			title
			# catchText {
			# 	text
			# }
			date(formatString: "YYYY年MM月DD日")
			modified(formatString: "YYYY年MM月DD日")
			tags {
				nodes {
					name
				}
			}
			featuredImage {
				node {
					altText
					localFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								quality: 100
								placeholder: BLURRED
								layout: CONSTRAINED
								formats: [AUTO, WEBP, AVIF]
							)
						}
					}
				}
			}
		}
		allWpPost(
			limit: 4
			sort: { fields: date, order: DESC }
			filter: {
				tags: { nodes: { elemMatch: { name: { in: $tag } } } }
				slug: { ne: $slug }
			}
		) {
			edges {
				node {
					uri
					title
					date(formatString: "YYYY年MM月DD日")
					tags {
						nodes {
							name
						}
					}
					featuredImage {
						node {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										quality: 100
										sizes: "400"
										placeholder: BLURRED
										layout: CONSTRAINED
										formats: [AUTO, WEBP, AVIF]
									)
								}
							}
						}
					}
				}
			}
		}
	}
`;
